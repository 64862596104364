import { createAsync } from '@solidjs/router';
import { Button, Heading, Link, Tag } from '@troon/ui';
import { Suspense, createUniqueId, Show, Switch, Match } from 'solid-js';
import { UserRewardsLevel, gql } from '../../graphql';
import { cachedQuery } from '../../graphql/cached-get';
import { useUser } from '../../providers/user';
import { dayTimeToDate } from '../../modules/date-formatting';
import type { CalendarDayTime } from '../../graphql';

export default function Settings() {
	const rewards = createAsync(() => getRewards({}), { deferStream: true });
	const progressId = createUniqueId();
	const user = useUser();

	return (
		<Suspense>
			<Show when={user()}>
				{(user) => (
					<>
						<h1 class="sr-only">My account</h1>
						<div class="flex flex-row items-center justify-between">
							<div class="flex flex-col gap-1">
								<Heading as="h2" size="h1">
									{user()?.me.firstName} {user()?.me.lastName}
								</Heading>
								<p class="text-neutral-700">{user()?.me.email}</p>
							</div>
							<Button as={Link} href="/auth/logout" appearance="secondary" class="hidden shrink grow-0 lg:block">
								Log out
							</Button>
						</div>

						<Switch>
							<Match when={user()?.activeTroonCardSubscription}>
								{(sub) => (
									<p class="flex flex-row flex-wrap items-center gap-2 rounded bg-neutral-100 p-3">
										<Tag appearance="access">{sub().name}</Tag>
										<span>
											You’re a <b>{sub().name}</b> member.
											<Show when={sub().nextInvoiceDate as CalendarDayTime}>
												{(date) => <> Renewing {formatDay(dayTimeToDate(date()))}.</>}
											</Show>
										</span>
									</p>
								)}
							</Match>
						</Switch>

						<Heading as="h3" size="h4">
							Troon Rewards
						</Heading>

						<div class="flex flex-col gap-6 rounded border border-neutral p-6">
							<div class="flex flex-row items-center justify-between">
								<div class="flex flex-col gap-1">
									<Heading as="h4" size="p" class="text-neutral-700">
										Total points available
									</Heading>
									<p class="text-5xl font-semibold">
										<Suspense fallback="…">{pointFormatter(rewards()?.userRewards.availablePoints ?? 0)}</Suspense>
									</p>
								</div>
								<Button as={Link} appearance="secondary" href="/account/activity" class="shrink grow-0">
									View Activity
								</Button>
							</div>
							<hr class="border-neutral-500" />
							<div>
								<p>
									<span class="text-lg font-semibold">
										<Suspense fallback="…">{pointFormatter(rewards()?.userRewards.currentYearPoints ?? 0)}</Suspense>
									</span>{' '}
									<span class="text-neutral-700">
										points earned (<abbr title="Year to date">YTD</abbr>)
									</span>
								</p>

								<div
									role="progressbar"
									aria-valuenow={rewards()?.userRewards.availablePoints}
									aria-valuemin={0}
									aria-valuemax={
										(rewards()?.userRewards.availablePoints ?? 0) + (rewards()?.userRewards.pointsToNextLevel ?? 0)
									}
									aria-valuetext={`${pointFormatter(rewards()?.userRewards.availablePoints ?? 0)} points`}
									aria-labelledby={progressId}
									class="h-6 rounded-full bg-brand-100"
								>
									<div
										class="h-6 min-w-6 rounded-full bg-brand"
										style={{
											width: `${((rewards()?.userRewards.availablePoints ?? 0) / ((rewards()?.userRewards.availablePoints ?? 0) + (rewards()?.userRewards.pointsToNextLevel ?? 0))) * 100}%`,
										}}
									/>
								</div>

								<Show when={rewards()?.userRewards.nextLevel}>
									<p class="text-right leading-none">
										<span class="block text-lg font-semibold">
											{pointFormatter(
												(rewards()?.userRewards.availablePoints ?? 0) + (rewards()?.userRewards.pointsToNextLevel ?? 0),
											)}
										</span>
										<Suspense>
											<span class="text-neutral-700">{levelToTitle[rewards()!.userRewards.nextLevel!]}</span>
										</Suspense>
									</p>
								</Show>
							</div>{' '}
						</div>
					</>
				)}
			</Show>
		</Suspense>
	);
}

const query = gql(`
query userRewards {
  userRewards {
		availablePoints
		currentYearPoints
		previousYear1Points
		previousYear2Points
		previousYear3Points
		level
		pointsToNextLevel
		nextLevel
	}
}`);

const getRewards = cachedQuery(query);

const pointFormatter = new Intl.NumberFormat('en', {}).format;

const levelToTitle: Record<UserRewardsLevel, string> = {
	[UserRewardsLevel.Member]: 'Member',
	[UserRewardsLevel.Silver]: 'Silver',
	[UserRewardsLevel.Gold]: 'Gold',
	[UserRewardsLevel.Platinum]: 'Platinum',
};

const formatDay = new Intl.DateTimeFormat('en-us', { month: 'short', day: 'numeric', year: 'numeric' }).format;
